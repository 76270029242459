document.addEventListener('DOMContentLoaded', function() {
    var markAsReadLink = document.querySelector('.ssp-notifications__mark-as-read');

    if (markAsReadLink) {
        markAsReadLink.addEventListener('click', function(e) {
            e.preventDefault(); // Prevent default link action

            var xhr = new XMLHttpRequest();
            xhr.open('POST', ssp_ajax.url, true); // Use the localized URL for AJAX
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

            xhr.onload = function() {
                if (xhr.status >= 200 && xhr.status < 400) {
                    var response = JSON.parse(xhr.responseText);

                    if (response.success) {
                        // Remove the 'has-new' class and reset the notification count
                        var notificationIcon = document.querySelector('.ssp-topbar__icon-notifications');
                        if (notificationIcon) {
                            notificationIcon.classList.remove('has-new');
                            notificationIcon.setAttribute('data-count', '0');
                        }

                        // Remove the 'ssp-notifications__item--new' class from all new notifications
                        var newItems = document.querySelectorAll('.ssp-notifications__item--new');
                        newItems.forEach(function(item) {
                            item.classList.remove('ssp-notifications__item--new');
                        });

                        console.log(response.data.message); // Log the success message
                    } else {
                        console.error(response.data.message); // Log the error message
                    }
                }
            };

            // Send the AJAX request with the user ID
            var params = 'action=ssp_mark_comments_as_read&user_id=' + encodeURIComponent(ssp_ajax.user_id);
            xhr.send(params);
        });
    }
});